import PropTypes from 'prop-types'
import React from 'react'
import imgMode13h from '../images/mode13h.jpg'
import imgWltetris from '../images/wltetris.jpg'
import imgRadClimber from '../images/radclimber.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        role="link"
        tabIndex="0"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyDown={() => {
          this.props.onCloseArticle()
        }}
      >
      </div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="whoami"
          className={`${this.props.article === 'whoami' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">whoami</h2>
          <p>
          I'm a geek and proud of it.<br></br>
          I code, automate and build infrastructures.<br></br>
          I run Linux and FreeBSD, dislike Windows.<br></br>
          I love running, from track, road, trails to mountains.<br></br>
          I like anime, retro gaming, nature photography, doodling and making crafts.<br></br>
          </p>
          <div className="logo">
          <i className="fa-brands fa-linux fa-2x"></i>&nbsp;
          <i className="fa-brands fa-suse fa-2x"></i>&nbsp;
          <i className="fa-brands fa-freebsd fa-2x"></i>&nbsp;
          <i className="fa-solid fa-person-running fa-2x"></i>&nbsp;
          <i className="fa-solid fa-mountain fa-2x"></i>&nbsp;
          <i className="fa-solid fa-camera fa-2x"></i>&nbsp;
          <i className="fa-solid fa-palette fa-2x"></i>&nbsp;
          <i className="fa-solid fa-dragon fa-2x"></i>
          </div>
          {close}
        </article>

        <article
          id="resume"
          className={`${this.props.article === 'resume' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">resume</h2>
          <ul>
            <li>
              <a href="https://www.linkedin.com/in/wufelix/" target="_blank" rel="noreferrer">
              <i className="fa-solid fa-gem"></i> Resume  - See Linked&nbsp;
              <i className="fa-brands fa-linkedin"></i> 
              </a>
            </li>
            <li>
              <a href="FelixWuRHCSA.pdf" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-redhat"></i> RHCSA
              </a>
            </li>
            <li>
              <a href="FelixWuWAS8.pdf" target="_blank" rel="noreferrer">
              <i className="fa-solid fa-globe"></i> WebSphere ND
              </a>
            </li>
            <li>
              <a href="USPatent8185459B2.pdf" target="_blank" rel="noreferrer">
              <i className="fa-solid fa-registered"></i> DDD Patent
              </a>
            </li>

          </ul>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'mode13h' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">mode 13h</h2>
          <a href="https://github.com/runwuf/libmode13h" target="_blank" rel="noreferrer">
          <i className="fa-brands fa-github"></i> source 
          </a>
          <br></br>
          <a href="libmode13h.zip" target="_blank" rel="noreferrer">
          <i className="fa-solid fa-cloud-arrow-down"></i> download
          </a>
          <p>
            This was my game library written in C with embedded x86 assembly, 
            compiled under Borland C. It includes most of the functionality required for a DOS game: 
            video, mouse, keyboard handlers. 
            It also has an Autodesk FLI animation file playback function.
          </p>
          <p>
            A sample test scroller is included, that loads PCX maps and tiles, 
            using embedded assembler to make fast and smooth scrolling with double buffer 
            writes directly to the video memory. scroll takes a parameter for number of pixel 
            to shift each time, without any parameter the default is 2 pixels. 
            scroll 4 results fast smooth scrolling with any 486 or faster CPU.
          </p>
          <span className="image main">
            <img src={imgMode13h} alt="" />
          </span>
          {close}
        </article>

        <article
          id="wltetris"
          className={`${this.props.article === 'wltetris' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">wildlife tetris</h2>
          <a href="https://github.com/runwuf/wltetris" target="_blank" rel="noreferrer">
          <i className="fa-brands fa-github"></i> source 
          </a>
          <br></br>
          <a href="https://archive.org/details/WildLifeTetris_1020" target="_blank" rel="noreferrer">
          <i className="fa-solid fa-gamepad"></i> play
          </a>
          <br></br>
          <a href="wltetris.zip" target="_blank">
          <i className="fa-solid fa-cloud-arrow-down"></i> download
          </a>
          <span className="image main">
            <img src={imgWltetris} alt="" />
          </span>
          <p>
            This was my first DOS game attempt back in high school days.
            The entire game was written in C and embedded x86 assembly, compiled under Borland C.
            Code was optimized for running in mode13h 320x200x256c in DOS. 
            The images were compressed using RLC - run length encoding compression which is really the same as the compression algorithm PCX uses.
            The code was poorly designed and not documented.
          </p>
          <p>
            Now you can play this online with a web browser, big thanks to <a href="https://archive.org/details/WildLifeTetris_1020" target="_blank" rel="noreferrer">archive.org</a>/DOS Museum.
            Use arrows keys and space (clockwise rotation) or keypad with 2, 4, 6, 8 and 5 (counter-clockwise rotation).
          </p>
          {close}
        </article>

        <article
          id="racestats"
          className={`${this.props.article === 'racestats' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">race stats</h2>
          <p>
          This was legacy php code does scraping on racestats html, it needs to be updated...
          </p>
          <p>
          Back in 2011, I was running a great race in Mississauga Marathon. 
          I was on my way to a personal record with a negative split, 
          just under a minute faster in my second half. 
          With about 2-3 km to go, There were 2 runners picked up their pace significantly 
          that I was unable to keep up, then they passed me. 
          Out of curiosity, I wanted to find out how many runners passed me late in the game, 
          so I wrote this to get some fun stats out of Marathon race results.
          </p>
          <p>
          It turns out there were 3 runners passed me, not 2! obviously I was too exhausted to count properly...&nbsp;
          <i className="fa-solid fa-face-sad-cry"></i>
          <i className="fa-solid fa-face-grin-beam-sweat"></i>
          <i className="fa-solid fa-face-grin-squint"></i>
          </p>
          {close}
        </article>

        <article
          id="radclimber"
          className={`${this.props.article === 'radclimber' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">rad climber</h2>
          <i className="fa-brands fa-github"></i> source coming soon...
          <br></br>
          <a href="https://www.mec.ca/_maintenance/" target="_blank" rel="noreferrer">
          <i className="fa-solid fa-gamepad"></i> click on the cyclist with flat tire to play
          </a>
          <p>
            This was a retro style endless runner game I created in one of the MEC hackathon.
            This mini game is hidden on the maintenance page for error 500 on <a href="https://www.mec.ca/" target="_blank" rel="noreferrer">mec.ca</a><br></br>
            It was inspired from the <a href="https://en.wikipedia.org/wiki/Dinosaur_Game" target="_blank" rel="noreferrer"><i className="fa-brands fa-chrome"></i> Chrome Dino</a>.
            The name Rad Climber was from the legendary <a href="https://www.mec.ca/en/article/mec-rad-pants-are-back" target="_blank" rel="noreferrer">Rad Pants</a>.
          </p>
          <img src={imgRadClimber} alt="" />
          <p>
            Game was created with <a href="https://ctjs.rocks/" target="_blank" rel="noreferrer">ct.js</a><br></br>
            Sprites were created with <a href="https://medibangpaint.com/" target="_blank" rel="noreferrer">MediBang Paint</a>     
          </p>
          {close}
        </article>

        <article
          id="art"
          className={`${this.props.article === 'art' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">art</h2>
          <p>
            Some of my imagination expressed here through drawings & crafts.&nbsp;<i className="fa-solid fa-palette fa-1x"></i>&nbsp;
            <a href="https://flic.kr/s/aHsm2DwpZW" target="_blank" rel="noreferrer">Art Gallery</a>
          </p>
          <p>
            Amazing views caputred on camera during my runs.&nbsp;<i className="fa-solid fa-camera fa-1x"></i>&nbsp;
            <a href="https://flic.kr/s/aHskVctpbK" target="_blank" rel="noreferrer">Photo Gallery</a>
          </p>
          {close}
        </article>

        <article
          id="shop"
          className={`${this.props.article === 'shop' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">shop</h2>
          <p>
            I wanted some t-shirt design with trail & mountain runners so I've designed a few myself
            &nbsp;<i className="fa-solid fa-shirt"></i>&nbsp;
            <a href="https://www.redbubble.com/people/runwuf/shop" target="_blank" rel="noreferrer">merch shop</a>
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">contact</h2>
          <ul className="icons">
            <li>
              <a href="https://github.com/runwuf" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-github"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/runwuf" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-instagram-square"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/wufelix/" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="https://stackoverflow.com/users/9672954/runwuf" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-stack-overflow"></i>
              </a>
            </li>
            <li>
              <a href="https://www.flickr.com/photos/goldenearsmonkey" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-flickr"></i>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
