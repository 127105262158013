import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; Gatsby Starter - Dimension.</p>
        <p className="copyright">
            Built with: 
            <a href="https://html5up.net"><i className="fa-brands fa-html5"></i>HTML5 UP</a><i className="fa fa-circle-dot"></i>
            <a href="https://www.gatsbyjs.org/">Gatsby.js</a><i className="fa fa-circle-dot"></i>
            <a href="https://fontawesome.com/"><i className="fa-brands fa-square-font-awesome"></i>fontawesome</a>
        </p>
        <p className="copyright">&copy; runwuf . felix.wu . v2021</p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
