import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="fa-solid fa-yin-yang fa-2x fa-spin fa-fw"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>HELLO, WORLD!</h1>
        <h5>
          Here's some random stuff about
        </h5>
        <p>
          felix.wu
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('')
            }}
          >
            jump!
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('whoami')
            }}
          >
            whoami
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('resume')
            }}
          >
            resume
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('mode13h')
            }}
          >
            mode 13h
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('wltetris')
            }}
          >
            wildlife tetris
          </button>
        </li>
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('racestats')
            }}
          >
            race stats
          </button>
        </li> */}
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('radclimber')
            }}
          >
            rad climber
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('art')
            }}
          >
            art
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('shop')
            }}
          >
            shop
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  onJumpRunWuf: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
